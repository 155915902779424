@use 'global' as *;
.cta {
  margin-bottom: 50px;
  margin-top: 30px;
  position: relative;

  @include container;

  &[data-placement='sidebar'] {
    margin-top: 0;
    z-index: 1;

    .cta_wrapper {
      padding: rfs(50px 32px 60px);

      .cta_container {
        h2 {
          margin-bottom: 3px;

          @include font-size(20px, 29px);

          @include media($small) {
            max-width: rfs(215px);
          }
        }

        p {
          line-height: 1.5rem;
          margin-bottom: 16px;

          @include media($small) {
            max-width: rfs(254px);
          }
        }

        .stats_container {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 32px;
          grid-template-columns: unset;
          margin: 26px 0 0;

          li {
            max-width: unset;

            &:first-child,
            &:last-child {
              border: none;
              justify-content: center;
            }

            div {
              margin: 0;

              h3 {
                font-family: var(--font-family-headings);
                letter-spacing: -0.4px;
                margin: 0;

                @include font-size(32px, 38px);

                @include media($small) {
                  margin-bottom: 3px;
                }
              }

              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.content_container {
  padding: 0 0 10px;
}

.cta_background {
  background: var(--background);
  margin: 0;
  max-width: 100%;
  padding: 0;

  &::before {
    background: rgb(255 255 255 / 90%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .cta_wrapper {
    background: transparent;
    box-shadow: none;
    max-width: unset;
    position: relative;

    .cta_container {
      max-width: rfs(750px);

      p {
        max-width: rfs(650px);
      }

      .stats_container {
        li {
          max-width: unset;
        }
      }
    }
  }
}

.theme_keyline {
  max-width: calc(100vw - 40px);
  padding: 0;
  z-index: 3;

  @include media($small) {
    max-width: rfs(1019px);
  }

  .cta_wrapper {
    box-shadow: none;
    margin-left: 10px;
    padding: rfs(64px 25px 49px);
    position: relative;

    &::before {
      border: 2px solid var(--color-gray-2);
      content: '';
      height: 100%;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 100%;
      z-index: -1;
    }

    .cta_container {
      max-width: rfs(630px);

      .stats_container {
        display: grid;
        gap: 0;
        justify-content: center;
        margin-top: 10px;

        @include media($small) {
          gap: 32px;
          grid-template-columns: repeat(3, minmax(10px, 1fr));
        }

        li {
          display: grid;

          &:first-child {
            @include media($small) {
              border-right: solid 2px var(--color-gray-3);
              justify-content: flex-start;
            }
          }

          &:nth-child(2) {
            justify-content: center;
          }

          &:last-child {
            @include media($small) {
              border-left: solid 2px var(--color-gray-3);
              justify-content: flex-end;
            }
          }

          div {
            margin: 10px 0;
          }

          h3 {
            font-family: var(--font-family-headings);
            letter-spacing: -0.4px;
            margin: 0;

            @include font-size(32px, 38px);

            @include media($small) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

.cta_wrapper {
  background: var(--color-gray-1);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  margin: 0 auto;
  max-width: rfs(1038px);
  padding: rfs(47px 14px 40px);
  width: 100%;

  @include media($small) {
    padding: rfs(70px 14px 54px);
  }
}

.cta_container {
  display: grid;
  margin: 0 auto;
  place-items: center;
  text-align: center;

  h2 {
    margin-bottom: 5px;

    @include media($small) {
      margin-bottom: 11px;
    }
  }

  p {
    @include body-b1;

    margin-bottom: 10px;

    @include media($small) {
      margin-bottom: 21px;
    }
  }

  .cta_link {
    @include link;
  }

  @include media($small) {
    max-width: rfs(477px);
  }
}

.stats_container {
  @include reset-list;

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @include media($small) {
    align-items: baseline;
    flex-direction: row;
    margin-top: 10px;
  }

  li {
    max-width: rfs(195px);

    h3 {
      font-family: var(--font-family-headings);
      font-weight: 500;
      margin: 10px 0 0;

      @include font-size(32px, 38px);
    }

    p {
      @include body-b1;

      margin: 0;
    }
  }
}
