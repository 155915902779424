@use 'global' as *;
.blog_landing_category {
  display: inline-flex;
  justify-content: flex-start;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
}

.blog_landing_view_all {
  margin: auto 0;
  padding-bottom: 12px;
  padding-left: 12px;
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    padding-bottom: 18px;
  }
}

.category_title {
  padding-right: 10px;
}
