@use 'global' as *;
.header_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: rfs(1038px);

  @include media($small) {
    align-items: center;
    flex-direction: row;
  }
}

.see_all_link {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.loading_container {
  align-items: center;
  display: flex;
  height: 350px;
  justify-content: center;
  width: 100%;
}
