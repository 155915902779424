@use 'global' as *;
.property_like_button {
  $size: 30px;

  background-color: rgb(80 80 80 / 50%);
  border-radius: 50%;
  color: transparent;
  height: $size;
  position: absolute;
  right: 12px;
  top: 11px;
  transition: background-color 0.25s ease-out;
  width: $size;
  z-index: var(--z-index-plus-one);

  &:hover {
    background-color: rgb(80 80 80 / 75%);
    color: var(--color-white);
    text-decoration: none;
  }

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &_is_active {
    color: var(--color-white);
  }

  &_header {
    background-color: var(--color-white);
    color: black;
    height: 20px;
    position: relative;
    width: 20px;

    &:hover {
      background-color: var(--color-white);
      color: black;
    }

    &_is_active {
      color: var(--color-black);
      transition: background-color 0.25s ease-out;
    }
  }
}

.header_save {
  color: var(--color-gray-4);
  display: none;
  font-family: var(--font-family-medium);
  font-size: 0.75rem;
  height: 20px;
  left: 25px;
  position: relative;
  text-decoration: underline;
  width: 30px;

  @include media($small-lowest) {
    display: block;
  }
}
