@use 'font' as *;
@use 'media' as *;
@use 'support' as *;

// ---------------------------------------------------------

@mixin default-button {
  @include font-size(12px, 18px);

  border: {
    radius: 4px;
    style: solid;
    width: 1px;
  }

  color: var(--color-white);
  display: inline-block;
  font-family: var(--font-family-medium);
  font-weight: 500;
  padding: 7px 24px 8px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;

  @include hover {
    color: var(--color-white);
    text-decoration: none;
  }
}

// ---------------------------------------------------------

@mixin header-links {
  @include font-size(12px, 16px);

  color: var(--color-black);
  text-transform: uppercase;

  @include media($full-navigation) {
    align-items: center;
    color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    font-weight: 600;
    margin-right: 17px;
    padding: 23.5px 6.5px 24px;
    transition: color 250ms ease-out;
  }

  @include hover {
    text-decoration: none;

    @include media($full-navigation) {
      color: var(--color-white);
    }
  }

  &::after {
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: -6.5px;
    min-width: 1%;
    position: relative;
    top: 3px;
    transition: min-width 300ms ease-in-out;
  }

  @include hover {
    &::after {
      @include media($full-navigation) {
        background-color: var(--color-red-secondary);
        min-width: calc(100% + 13px);
      }
    }
  }
}

// ---------------------------------------------------------

@mixin zero-state {
  @include font-size(14px, 20px);

  background-color: var(--color-gray-2);
  border-radius: 4px;
  margin-bottom: 60px;
  padding: 18px;
  text-align: center;
}
