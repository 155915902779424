@use 'global' as *;
.squares_container {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, minmax(10px, 1fr));
  row-gap: 10px;

  @include media($small) {
    column-gap: 20px;
    row-gap: 20px;
  }
}

// ---------------------------------------------------------

.square {
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-medium);
  justify-content: space-between;
  transition: transform 300ms ease-in-out;

  h3 {
    @include font-size(14px, 20px);

    color: var(--color-black);
    font-family: var(--font-family-medium);
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  span {
    @include font-size(12px, 20px);

    align-items: center;
    color: var(--color-red-primary);
    display: inline-flex;
    font-family: var(--font-family-medium);
    margin-left: 8px;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
}

.square_image {
  display: flex;
  margin: auto;
  margin-bottom: 15px;
  max-width: 30px;

  @include media($small) {
    margin-bottom: 20px;
    max-width: 64px;
  }
}

// ---------------------------------------------------------

.theme_2x2 {
  .square {
    padding: rfs(36px 30px);
  }
}

.theme_1x4 {
  .square {
    padding: rfs(37px 12px);
  }

  &.squares_container {
    @include media($medium-lowest) {
      grid-template-columns: repeat(4, minmax(10px, 1fr));
    }
  }
}

@media only screen and (max-width: 600px) {
  .square {
    &:focus {
      text-decoration: none;
      transform: scale(1.05);
    }

    span {
      font-size: 9px;
    }

    h3 {
      font-size: 12px;
    }
  }

  .theme_2x2.square {
    padding-bottom: 44px;
  }
}
