@use 'global' as *;
.card {
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  max-width: 400px;
  position: relative;
  transition: box-shadow 0.25s ease-out;

  @include hover {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);
  }
}

.office_image {
  > span {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  }

  img {
    height: 200px;
  }
}

// ---------------------------------------------------------

.card_bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

// ---------------------------------------------------------

.office_details {
  padding: 10px 15px 22px;

  @include hover {
    color: var(--color-black);
    text-decoration: none;
  }
}

.office_name {
  @include font-size(24px, 29px);

  color: var(--color-black);
  display: block;
  letter-spacing: -0.5px;
}

.office_address {
  @include font-size(14px, 20px);

  color: var(--color-black);
  display: block;
  font-family: var(--font-family-medium);
  font-size: 14px;
  line-height: 20px;
}

// ---------------------------------------------------------

.office_contact {
  @include font-size(10px, 30px);

  background-color: var(--color-gray-1);
  color: var(--color-gray-5);
  display: flex;
  font-family: var(--font-family-medium);
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 6px;
  text-align: center;
  width: 100%;

  .contact_link {
    display: flex;
    width: 100%;

    a {
      align-items: center;
      color: var(--color-gray-5);
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: rfs(0 16px);
      width: 100%;

      svg {
        margin-right: 6px;
      }
    }
  }

  .contact_link + .contact_link::before {
    border-left: 1px solid var(--color-gray-3);
    content: '';
    height: 30px;
  }
}
