@use 'global' as *;
.buyside_form {
  margin: 0 auto;
  max-width: 600px;
  position: relative;
  width: 100%;

  > div {
    padding: 0;

    > div {
      padding: 0;

      input {
        @include font-size(16px, 19px);

        background-color: var(--color-white);
        border: solid 0.5px rgb(142 142 147 / 42%);
        border-radius: 4px 0 0 4px;
        color: var(--color-black);
        display: flex;
        font-family: var(--font-family-base);
        padding: 7px 10px 8px 11px;
        width: 100%;

        &::placeholder {
          color: var(--color-black);
          font-size: 12px;
          opacity: 100%;
        }
      }

      // Autocomplete dropdown

      > div:nth-of-type(2) {
        border: solid 1px var(--color-gray-3);
        max-width: 485px;
        padding: 0 5px;
        position: absolute;
        top: 35px;
        width: 100%;

        // Autocomplete dropdown items

        div {
          border-bottom: solid 1px var(--color-gray-2);
          padding: 10px 8px;

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }

    button {
      @include font-size(12px, 36px);

      background-color: var(--color-black);
      border-radius: 0 4px 4px 0;
      color: var(--color-white);
      font-family: var(--font-family-medium);
      padding: rfs(0 24px);
      white-space: nowrap;
    }

    .buyside_widget_autocomplete-items {
      padding: 10px;
    }
  }

  &.theme_unifed_search {
    margin: 0 auto;
    margin-bottom: 12px;
    max-width: unset;
    position: relative;
    width: 100%;

    > div {
      align-items: center;
      display: flex;
      padding: 0;

      > div {
        box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
        padding: 0;

        input {
          background-color: var(--color-white);
          border: none;
          border-radius: 0;
          display: flex;
          font-family: var(--font-family-base);
          font-size: 16px;
          height: 48px;
          line-height: normal;
          padding: 16px 58px 15px 16px;
          position: relative;
          transition: box-shadow 0.25s ease-in-out;
          z-index: var(--z-index-plus-one);

          @include media($small) {
            font-size: 14px;
            padding: 16px 45px 16px 16px;
          }

          &:focus-within,
          &:hover {
            box-shadow: 0 0 12px 0 rgb(0 0 0 / 14%), 0 0 4px 0 rgb(0 0 0 / 14%);
          }

          &::placeholder {
            color: var(--color-gray-4);
            font-family: var(--font-family-base);
            font-size: 16px;

            @include media($small) {
              font-size: 14px;
            }
          }

          &:focus,
          &:focus-visible {
            outline: none !important;
          }
        }

        // Autocomplete dropdown

        > div:nth-of-type(2) {
          border: solid 1px var(--color-gray-3);
          max-width: unset;
          position: absolute;
          top: 48px !important;
          width: 100%;

          // Autocomplete dropdown items

          div {
            border-bottom: solid 1px var(--color-gray-2);
            padding: 10px 8px;

            &:last-of-type {
              border-bottom: 0;
            }
          }
        }
      }

      button {
        @include font-size(14px, 22px);

        align-items: center;
        background-color: var(--color-gray-1);
        border-radius: 50%;
        color: var(--color-black);
        display: flex;
        height: 2.25rem;
        justify-content: center;
        padding: unset;
        position: absolute;
        right: 12px;
        transition: background-color 0.25s ease-in-out;
        width: 2.25rem;
        z-index: 2;
      }

      .buyside_widget_autocomplete-items {
        padding: 10px;
      }
    }
  }
}
