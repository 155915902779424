@use 'global' as *;
.input_container {
  position: relative;
  width: 100%;
}

// ---------------------------------------------------------

.input_field {
  background-color: var(--color-gray-1);
  border: 0.5px solid var(--color-gray-3);
  font-family: var(--font-family-base);
  padding: 9px 16px 6px;
  width: 100%;

  &:focus {
    border-color: var(--color-gray-4);
    outline: 0;
  }

  &.has_error {
    background-color: var(--color-yellow-alpha40);
    border: solid 1px var(--color-yellow);
  }
}

// ---------------------------------------------------------

.input_label {
  @include font-size(12px, 20px);

  font-family: var(--font-family-medium);
  font-weight: 500;

  span {
    display: block;
    margin-bottom: 8px;
  }
}

// ---------------------------------------------------------

.error {
  align-items: center;
  color: var(--color-blue);
  display: flex;
  font-family: var(--font-family-base);
  padding-top: 6px;

  &::before {
    $size: 12px;

    @include font-size(10px, 17px);

    align-items: center;
    background-color: var(--color-blue);
    border-radius: 50%;
    color: var(--color-white);
    content: '!';
    display: flex;
    font-weight: 700;
    height: $size;
    justify-content: center;
    margin-right: 4px;
    width: $size;
  }
}

// ---------------------------------------------------------

.tag_container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
}

.tag {
  @include font-size(12px, 20px);

  background-color: var(--color-white);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 8px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  color: var(--color-black);
  margin-bottom: 5px;
  padding: 3px 25px 3px 15px;

  &:not(:last-of-type) {
    margin-right: 5px;
  }
}

// ---------------------------------------------------------

.dropdown_list {
  @include reset-list;

  background-color: var(--color-white);
  border: 1px solid var(--color-gray-1);
  display: none;
  max-height: 30vh;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: var(--z-index-plus-three);

  &.is_showing {
    display: block;
  }

  li {
    @include font-size(12px, 20px);

    display: flex;
    justify-content: space-between;
    padding: 8px 16px;

    &:focus::after,
    &:hover::after {
      color: var(--color-gray-3);
      content: '\2713';
      font-weight: var(--font-weight-bold);
    }

    &:focus {
      background-color: var(--color-gray-1);
      outline: 0;
    }

    &[aria-selected='true'] {
      color: var(--color-red-secondary);

      &::after {
        color: var(--color-red-secondary);
        content: '\2713';
        float: right;
      }
    }
  }
}

// -------------------------------------------------------------------

.input_icon {
  bottom: calc(10% + 7px);
  pointer-events: none;
  position: absolute;
  right: 16px;
  user-select: none;

  @include media($small) {
    right: 20px;
  }
}
