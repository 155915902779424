@use 'global' as *;
.dropdown {
  @include header-links;
  @include font-size(16px, 24px);

  cursor: default;
  font-family: var(--font-family-medium);

  svg {
    display: none;
  }

  @include media($full-navigation) {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;

    svg {
      display: flex;
      margin-left: 5px;
      transition: transform 200ms ease-in-out;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.dropdown_is_showing {
  &::after {
    @include media($full-navigation) {
      background-color: var(--color-red-secondary);
      width: 100%;
    }
  }

  svg {
    transform: rotate(-180deg);
  }
}

// ---------------------------------------------------------

.dropdown_menu {
  @include media($full-navigation, down) {
    border-bottom: 1px solid var(--color-gray-2);
    margin-bottom: 22px;
    padding-bottom: 18px;
  }

  @include media($full-navigation) {
    background-color: var(--color-white);
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
    min-width: rfs(260px);
    position: absolute;
  }
}

.dropdown_menu_inner {
  @include media($full-navigation) {
    padding: 20px 15px 24px;
  }

  li {
    span {
      @include font-size(12px, 28px);

      color: var(--color-gray-4);
      display: block;
      font-family: var(--font-family-medium);

      @include media($full-navigation) {
        @include font-size(12px, 18px);

        margin-bottom: 8px;
      }
    }

    a {
      @include font-size(12px, 28px);

      @include media($full-navigation) {
        @include font-size(13px, 24px);
      }

      color: var(--color-black);
      font-weight: 300;
      padding: 0;
      text-transform: none;

      &::after {
        display: none;
      }

      @include hover {
        color: var(--color-red-primary);
      }

      &.dropdown_link_is_active {
        color: var(--color-red-primary);
      }
    }
  }
}

// ---------------------------------------------------------

.dropdown_links_heading {
  @include font-size(12px, 18px);

  color: var(--color-gray-4);
  font-family: var(--font-family-medium);

  &:not(:first-of-type) {
    margin-top: 18px;

    @include media($full-navigation) {
      margin-top: 26px;
    }
  }
}

// ---------------------------------------------------------

.my_account_dropdown {
  &.dropdown_menu {
    @include media($full-navigation) {
      min-width: rfs(175px);
      position: absolute;
      right: 6px;
      text-align: right;
    }
  }

  .dropdown_menu_inner {
    @include media($full-navigation) {
      padding-right: 38px;
    }

    li {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
