@use 'media' as *;
@use 'sass:math';

// --------------------------------------------------------

@mixin container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--global-max-width);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
}

@mixin container-global-nav {
  @include container;

  max-width: $medium + 40px;
}

// --------------------------------------------------------

@mixin has-background-image {
  display: grid;
  position: relative;

  > div {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}
