@use 'global' as *;
.spinner {
  animation: spin 1s linear infinite;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid var(--color-red-primary);
  height: 80px;
  width: 80px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
