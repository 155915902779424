@use 'global' as *;
.blog_archive {
  background: var(--color-white);

  main {
    margin: 0 auto;
    padding: 0;
  }
}

.blog_header {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
  padding: rfs(0 0 65px);

  @include media($medium) {
    flex-direction: row;
    margin: 0 auto;
    max-width: rfs(1038px);
    padding: rfs(50px 0);
  }
}

.blog_banner {
  display: grid;
  grid-template-areas: 'full';
  min-height: 195px;
  width: 100%;

  @include media($small) {
    min-height: 500px;
  }
}

.blog_banner_img {
  grid-area: full;
  width: 100%;

  div {
    min-height: 195px;

    @include media($small) {
      min-height: 500px;
    }
  }
}

.blog_cta {
  align-self: flex-end;
  background: var(--color-black);
  color: var(--color-white);
  grid-area: full;
  height: fit-content;
  padding: 12px 29px;
  width: 100%;
  z-index: 1;

  @include media($small) {
    margin-bottom: 18px;
    padding: 18px 41px;
  }
}

.blog_cta_text {
  position: relative;

  h1 {
    @include h2;

    margin-bottom: 0;

    @include media($medium) {
      margin-bottom: 3px;
      max-width: rfs(400px);
    }
  }

  &::before {
    background: var(--color-red-primary);
    content: '';
    height: 100%;
    left: -14px;
    position: absolute;
    width: 3px;

    @include media($small) {
      left: -18px;
      width: 5px;
    }
  }
}

.header_link {
  align-items: center;
  color: var(--color-white);
  display: inline-flex;
  letter-spacing: 0.05px;
  text-decoration: underline;

  @include font-size(9px, 16px);

  @include media($small) {
    @include font-size(12px, 20px);

    letter-spacing: 0.08px;
  }
}
