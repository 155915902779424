@use 'global' as *;
// --------------------------------------------------------

.footer_wrap {
  flex-shrink: 0;
}

.footer {
  background-color: var(--color-black);
}

.content_area {
  @include container-global-nav;
}

// ---------------------------------------------------------

@mixin top-links {
  color: var(--color-white);
}

.top {
  @include has-background-image;

  color: var(--color-white);

  .content_area {
    padding-bottom: rfs(61px);
    padding-top: rfs(82px);
    width: 100%;

    @include media($small) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.background_image {
  background-size: 117px 95px;
}

.logo_class {
  @include top-links;

  align-self: flex-start;
  height: rfs(51px);
  min-height: 29px;
  min-width: 130px;
  width: rfs(230px);

  svg {
    height: rfs(51px);
    width: 100%;
  }
}

.menu_links {
  li:not(:last-of-type) {
    margin-bottom: 8.5px;
  }

  a {
    @include top-links;
    @include font-size(12px, 20px);

    color: var(--color-white);
    font-family: var(--font-family-medium);
    letter-spacing: -0.18px;
  }
}

.leadingre_partner_links_container {
  align-items: initial;
  display: flex;
}

.leadingre_partner_links {
  @include reset-list;

  li:not(:last-of-type) {
    margin-bottom: 12px;
  }

  a {
    color: var(--color-black);
    width: 104px;
  }
}

.description_container,
.logo_container {
  @include media($small) {
    width: 25%;
  }

  @include media($medium-lowest) {
    width: 33.3%;
  }
}

.logo_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1.5px 0 22px;

  @include media($medium-lowest) {
    max-width: 238px;
  }
}

.link_container {
  display: flex;
  height: fit-content;
  justify-content: space-between;

  @include media($small) {
    width: 36%;
  }

  @include media($medium-lowest) {
    max-width: 297px;
    width: 33.3%;
  }
}

.description_container {
  margin-bottom: 20px;

  p {
    font-size: 12px;

    @include media($small) {
      margin-bottom: 36.5px !important;
      padding-right: 20px;
    }
  }

  a {
    color: var(--color-white);
    text-decoration: underline;
  }

  @include media($small) {
    margin-bottom: 0;
  }
}

// ---------------------------------------------------------

.bottom {
  background-color: var(--color-white);
  color: var(--color-gray-accessibility);
  padding-bottom: rfs(82px);
  padding-top: rfs(20px);

  small {
    @include font-size(10px, 19px);

    display: block;
  }

  a {
    color: var(--color-gray-accessibility);
  }
}

.partners_container {
  @include media($medium) {
    display: flex;
    justify-content: space-between;
  }
}

.partner_links_group {
  @include media($small-highest) {
    display: flex;
  }
}

@mixin partner-links {
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;

  @include media($medium) {
    margin-bottom: rfs(30px);
  }

  ul {
    margin-bottom: 0;
  }

  li {
    min-width: fit-content;

    &:not(:last-of-type) {
      margin-right: rfs(45px);
    }
  }

  a {
    min-height: 32px;
    mix-blend-mode: difference;
    opacity: 78%;
    transition: opacity 0.25s ease-in-out;

    @include media($small) {
      margin-bottom: 0;
    }

    @include hover {
      opacity: 90%;
    }
  }
}

.partner_links {
  @include partner-links;

  display: flex;
  flex-wrap: wrap;

  li {
    margin-bottom: 10px;
  }
}

.partner_links_set_two {
  @include partner-links;

  li {
    @include media($small-highest) {
      margin-left: rfs(45px);
    }

    &:last-of-type {
      @include media($medium) {
        margin: rfs(0 40px);
      }
    }
  }
}

.partner_badges {
  display: flex;
  flex-direction: row;
  margin-bottom: rfs(36px);

  div:not(:last-of-type) {
    padding-right: 10px;
  }

  @include media($small) {
    margin-bottom: 0;
    padding-top: 10px;
  }

  > span {
    /* transform: scale(0.8); */

    @include media($small) {
      transform: none;
    }

    &:not(:last-of-type) {
      margin-right: 8px !important;

      @include media($small) {
        margin-right: 16px !important;
      }
    }
  }
}

.legal_links {
  flex-direction: row;

  a {
    @include hover {
      color: var(--color-gray-5-alpha85);
    }
  }

  li:not(:last-of-type) {
    &::after {
      content: '|';
      display: inline-block;
      margin: 0 6px;
    }
  }
}

.social_nav_mobile {
  @include media($small) {
    display: none;
  }
}

.social_nav_desktop {
  display: none;

  @include media($small) {
    display: flex;
  }
}
