@use 'global' as *;
.accordion_item {
  border-top: 1px solid var(--color-gray-3);
  text-align: left;

  p,
  .heading,
  li {
    text-align: left !important;
  }
}

.title {
  text-align: center;
}

// ---------------------------------------------------------

.theme_red {
  .label_container {
    span,
    .heading {
      color: var(--color-red-primary);
    }
  }
}

// ---------------------------------------------------------

.label_container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  span,
  .heading {
    @include font-size(12px, 20px);

    font-family: var(--font-family-bold);
    letter-spacing: 0.38px;
    margin: 20px 0;
    text-transform: uppercase;

    @include media($small) {
      @include font-size(16px, 20px);

      letter-spacing: 0.5px;
    }
  }

  .icon {
    color: var(--color-red-primary);
    transform: rotate(0deg);
    transition: transform 0.35s ease-in-out;
  }
}

.label_container[aria-expanded='true'],
.label_container[aria-selected='true'] {
  .icon {
    transform: rotate(180deg);
  }
}

.content_container {
  padding-bottom: 20px;
}

// ---------------------------------------------------------

.topic_record_link {
  display: block;
  margin-top: 20px;
}
