@use 'global' as *;
.img {
  img {
    height: auto;
  }

  &.layout_is_fill {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

.background_image {
  position: relative;
}
