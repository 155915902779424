@use 'global' as *;
// ---------------------------------------------------------

.header {
  background-color: var(--color-red-primary);
  flex-shrink: 0;
  min-height: var(--navigation-height);
  position: relative;
  text-transform: uppercase;
  z-index: var(--z-index-plus-three);

  > div {
    @include media($full-navigation) {
      @include container-global-nav;

      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &.is_white {
    background-color: var(--color-white);
  }
}

// ---------------------------------------------------------

.logo_container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  // 32 is the 16px padding on the top and bottom of the header
  min-height: calc(var(--navigation-height) - 32px);
  padding: 16px;
  width: 100%;

  @include container-global-nav;

  @include media($full-navigation) {
    flex-grow: 1;
    padding: 0;
    width: auto;
  }
}

.logo_class {
  bottom: 1px;
  color: var(--color-white);
  position: relative;
  width: 149px;

  @include hover {
    color: var(--color-black-alpha85);
  }

  @include media($full-navigation) {
    margin-right: 20px;
  }

  @include media($full-navigation, down) {
    &.no_sign_in_buttons {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// ---------------------------------------------------------

.menu_button_container {
  @include media($full-navigation, down) {
    width: 20%;
  }

  .navigation_is_showing & {
    @include media($full-navigation, down) {
      width: auto;
    }
  }
}

.menu_open_button,
.menu_close_button {
  .screen_reader_title {
    @include screen-reader-only;
  }
}

.menu_open_button {
  color: var(--color-white);
  padding-right: 20px;

  .is_white & {
    color: var(--color-black);
  }
}

.menu_close_button {
  color: var(--color-gray-4);
  padding-left: 20px;
}

// ---------------------------------------------------------

.navigation_container {
  display: none;
  width: 100%;

  .navigation_is_showing & {
    background-color: var(--color-white);
    display: block;
    height: 100vh;
    left: 0;
    overflow: scroll;
    padding: 14px 14px 100px;
    position: absolute;
    top: 0;
    z-index: 1000;
  }

  @include media($full-navigation) {
    display: flex;
    justify-content: space-between;

    &.no_sign_in_buttons {
      width: unset;
    }
  }
}

// ---------------------------------------------------------

.desktop_navigation {
  display: none;

  @include media($full-navigation) {
    display: block;
  }
}

.mobile_navigation {
  @include media($full-navigation) {
    display: none;
  }
}

// ---------------------------------------------------------

.main_navigation_container {
  flex-wrap: wrap;

  @include media($full-navigation) {
    justify-content: flex-end;
  }

  li {
    @include media($full-navigation, down) {
      width: 100%;
    }
  }
}

// ---------------------------------------------------------

.navigation_header {
  > div {
    &:first-of-type {
      @include font-size(12px, 18px);

      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
  }
}

.home_link {
  border-bottom: 1px solid var(--color-gray-2);
  margin-bottom: 26px;
  padding-bottom: 8px;
  text-transform: capitalize;

  a {
    @include font-size(12px, 29px);

    color: var(--color-black);
  }
}

// ---------------------------------------------------------

.account_links {
  display: flex;
  position: relative;

  @include media($full-navigation, down) {
    justify-content: flex-end;
  }
}

.account_link {
  @include header-links;

  @include media($full-navigation, down) {
    color: var(--color-white);
    font-weight: var(--font-weight-semibold);
  }

  @include hover {
    color: var(--color-white);
  }
}

.account_button {
  @include default-button;

  background-color: var(--color-red-primary);
  border-color: var(--color-white);
  color: var(--color-white);
  margin: 15px 0;
  margin-left: 14px;
  text-transform: uppercase;

  @include hover {
    background-color: var(--color-white);
    border-color: var(--color-red-primary);
    color: var(--color-red-primary);
  }
}

// ---------------------------------------------------------

.mobile_account_heading {
  @include font-size(16px, 24px);

  color: var(--color-black);
  font-family: var(--font-family-medium);
  margin-bottom: 12px;
}

.mobile_account_menu_inner {
  @include media($full-navigation) {
    padding: 20px 15px 24px;
  }

  border-bottom: 1px solid var(--color-gray-2);
  margin-bottom: 22px;
  padding-bottom: 18px;

  li {
    a {
      @include font-size(12px, 28px);

      @include media($full-navigation) {
        @include font-size(13px, 24px);
      }

      color: var(--color-black);
      font-weight: 300;
      padding: 0;
      text-transform: none;

      &::after {
        display: none;
      }

      @include hover {
        color: var(--color-red-primary);
      }

      &.dropdown_link_is_active {
        color: var(--color-red-primary);
      }
    }
  }
}
