@use 'global' as *;
@use 'global/variables';
@use 'global' as *;

// --------------------------------------------------------

body {
  @include font-smoothing;

  color: var(--global-text-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  margin: 0;
}

// --------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-headings);
  font-weight: 300;
  margin: 0 0 10px;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

// --------------------------------------------------------

a {
  @include a;
}

p {
  @include font-size(16px, 24px);

  margin-bottom: 20px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

input,
textarea {
  font-size: 16px;

  @include media($small) {
    font-size: 14px;
  }
}

strong {
  font-weight: 700;
}

// ---------------------------------------------------------

button {
  @include reset-button;
}

select {
  @include reset-select;
}

// --------------------------------------------------------

img {
  max-width: 100%;
}

// --------------------------------------------------------

::selection {
  background-color: var(--color-red-primary);
  color: var(--color-white);
}

// --------------------------------------------------------

.grecaptcha-badge {
  visibility: hidden;
}

// --------------------------------------------------------
// React Photo-Swipe Light Box override for the LDP photo-gallery
/* stylelint-disable */

.pswp__default-caption {
  font-family: var(--font-family-base) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 2 !important;
  top: 15px;
  width: 40%;
  margin: 0 auto;
  position: relative !important;
  padding: 0px !important;
  min-width: 80px;
}

.pswp__counter {
  height: 50px !important;
  margin-left: 15px;
  position: relative;
}

.pswp__bg {
  opacity: 90% !important;
}

.pswp__item {
  z-index: 1;
  overflow: visible !important;
}
/* stylelint-enable */

// --------------------------------------------------------
// React DatePicker Calendar Override
/* stylelint-disable */
.react-datepicker__day--selected {
  background-color: var(--color-red-primary) !important;
  border-radius: 0px !important;
}

.react-datepicker {
  border-radius: 0px !important;
}
/* stylelint-enable */

// --------------------------------------------------------
// React Chat Widget Override

#chat-widget-container {
  bottom: -7px !important;
}
