@use 'global' as *;
.video_container {
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;

  .video_player {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
