@use 'global' as *;
.article_card {
  @include font-size(15px, 24px);

  display: flex;
  height: 100%;
  max-width: 400px;
  width: 100%;

  p {
    text-align: left;
  }

  @include hover {
    text-decoration: none;

    .article_link {
      text-decoration: underline;
    }
  }
}

// ---------------------------------------------------------

.article_card_container {
  border-radius: 4px;
  width: 100%;
}

.article_card_content {
  padding: 15px 15px 25px;
}

// ---------------------------------------------------------

.article_link {
  @include font-size(12px, 20px);

  align-items: center;
  color: var(--color-red-primary);
  display: flex;
  font-family: var(--font-family-medium);
}

// ---------------------------------------------------------

.theme_carousel {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 400px;
  min-height: 210px;
  width: 100%;

  h2 {
    @include font-size(16px, 20px);

    font-family: var(--font-family-medium);
  }

  .article_card_content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .article_link {
      margin-top: auto;
    }
  }
}

.theme_carousel,
.theme_community {
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  color: var(--color-black);
  max-width: 400px;

  .article_image {
    min-height: 140px;
  }
}

.theme_community {
  .article_eyebrow {
    @include font-size(12px, 20px);

    color: var(--color-gray-4);
    font-family: var(--font-family-medium);
    letter-spacing: 0.5px;
  }

  h2 {
    @include font-size(24px, 29px);

    font-family: var(--font-family-base);
    letter-spacing: -0.5px;
  }

  p {
    @include font-size(12px, 20px);
  }
}

// ---------------------------------------------------------

.theme_cta {
  background-color: var(--color-black);
  box-shadow: 0 0 4px 0 rgba(0 0 0 / 10%), 0 0 5px 0 rgba(0 0 0 / 34%);
  color: var(--color-white);
  display: flex;
  flex-direction: column;

  @include hover {
    color: var(--color-white);

    .article_image {
      &::before {
        opacity: 50%;
      }
    }

    .article_link {
      text-decoration: none;
    }
  }

  .article_image {
    display: grid;

    &::before,
    &::after {
      border-radius: 4px 4px 0 0;
      content: '';
      grid-column-start: 1;
      grid-row-start: 1;
      z-index: 1;
    }

    img {
      border-radius: 4px 4px 0 0;
    }

    &::before {
      align-self: flex-start;
      background-color: var(--color-black);
      min-height: 200px;
      opacity: 35%;
      transition: opacity 0.25s ease-out;
    }

    &::after {
      align-self: flex-end;
      background-image: linear-gradient(to bottom, rgb(0 0 0 / 0%), var(--color-black) 89%);
      height: 64px;
    }
  }

  .article_card_top {
    align-items: center;
    display: grid;
    position: relative;

    > * {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }

  .article_link {
    color: var(--color-white);

    svg {
      margin-left: 10px;
    }
  }

  .icon_container {
    filter: brightness(0) invert(1);
    z-index: 1;

    > div {
      margin: 0 auto;
      max-width: 80%;
    }
  }
}

// ---------------------------------------------------------

.theme_primary {
  @include font-size(18px, 24px);

  color: var(--color-black);
  display: grid;
  grid-template-rows: 206px 1fr;

  @include media($medium) {
    grid-template-rows: 234px 1fr;
  }

  p {
    margin-bottom: 15px;
  }

  .article_card_top {
    height: 234px;
  }

  .article_card_content {
    display: flex;
    flex-direction: column;
    height: 1fr;
    justify-content: space-between;
    padding: 15px 0 0;
  }

  .article_image {
    height: 206px;

    @include media($medium) {
      height: 234px;
    }
  }

  @include hover {
    color: var(--color-black);
  }
}
