@use 'global' as *;
@mixin bar($size: 10px, $left: -30px) {
  &::before {
    background: var(--color-red-primary);
    content: '';
    height: 100%;
    left: $left;
    position: absolute;
    width: $size;
  }
}

.jumbotron_banner {
  background-color: var(--color-white);
  display: grid;
  height: auto;
  position: relative;
  width: 100%;

  @include media($small) {
    grid-template-areas: 'full';
  }
}

.jumbotron_banner_bar {
  grid-template-areas: 'full';

  @include media($medium-highest) {
    min-height: rfs(396px);
  }

  .jumbotron_banner_cta {
    align-self: end;
    background: var(--color-black);
    width: 100%;
    z-index: 1;

    @include media($medium-highest) {
      background: repeating-linear-gradient(
        -55deg,
        var(--color-black),
        var(--color-black) 10px,
        #222 4px,
        #222 12px
      );
      margin-bottom: rfs(65px);
    }

    .jumbotron_banner_wrapper {
      padding: 20px 0;

      @include media($small) {
        padding: 24px 0;
      }

      .jumbotron_banner_content {
        color: var(--color-white);
        padding: 0 15px;

        @include bar(8px, -20px);

        .jumbotron_banner_buttons {
          align-items: flex-start;
          gap: 4px;

          a {
            text-align: left;
          }

          @include media($small) {
            &:has(> :nth-child(3)) {
              column-gap: 15px;
              display: grid;
              grid-template-columns: repeat(2, auto);
              justify-content: flex-start;

              > a {
                justify-self: flex-start;
              }
            }
          }

          @media only screen and (max-width: 750px) {
            width: calc(100% - 20px) !important;
          }
        }

        h1 {
          margin-bottom: 10px;

          @include media($medium-highest) {
            margin-bottom: 8px;
          }
        }

        p {
          font-weight: var(--font-weight-bold);
          justify-self: flex-start;
          margin: 10px 0 0;
          text-transform: uppercase;

          @include media($medium-highest) {
            margin: 8px 0;
          }
        }

        @include media($medium-highest) {
          @include bar(8px, -30px);

          // Calculate margin based on container width calculations
          margin-left: calc(((100vw - 1038px) * 0.5) + 24px);
        }
      }
    }
  }

  .jumbotron_banner_image {
    grid-area: full;
    position: relative;
  }
}

.jumbotron_banner_card {
  @include media($medium-highest) {
    min-height: 500px;
  }

  .jumbotron_banner_cta {
    @include media($medium-highest) {
      margin-bottom: rfs(50px);
      padding: rfs(45px 20px 50px 0);
      width: clamp(50%, 50vw, 820px);
    }

    .jumbotron_banner_content {
      @include media($medium-highest) {
        // Calculate margin based on container width calculations
        margin-left: calc(((100vw - 1038px) * 0.5) + 24px);
        padding: 10px 0;
        position: relative;
      }

      @include bar;

      h1 {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.jumbotron_banner_centered,
.jumbotron_banner_left_centered {
  @include media($small) {
    min-height: 363px;
    place-items: center;
  }

  .jumbotron_banner_cta {
    @include container;

    margin: 0;
    padding: 0;

    @include media($small) {
      margin-bottom: rfs(40px);
      max-width: 574px;
    }

    .jumbotron_banner_content {
      padding: 20px 15px 26px;

      @include media($small) {
        padding: rfs(68px 52px 33px);
        place-items: center;
        text-align: center;
      }

      h1 {
        margin-bottom: 11px;

        @include media($small) {
          margin-bottom: 8px;
        }
      }

      p {
        margin-bottom: 0;

        @include media($small) {
          margin-bottom: 16px;
        }
      }

      .jumbotron_banner_buttons {
        @include media($small) {
          margin: 0;
        }
      }
    }
  }

  .jumbotron_banner_image {
    height: 100%;
  }
}

.jumbotron_banner_buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;

  @include media($small) {
    margin-bottom: 6px;
    margin-top: 20px;
  }

  a {
    text-align: center;
  }
}

.jumbotron_banner_left_centered {
  .jumbotron_banner_buttons {
    align-items: inherit;

    @include media($small) {
      align-items: center;
    }
  }
}

.button_left_aligned {
  align-items: flex-start;
}

.jumbotron_banner_cta {
  align-self: end;
  background: var(--color-white);
  box-shadow: 0 2px 5px 0 rgba(0 0 0 / 11%);
  height: max-content;
  z-index: 1;

  @include media($medium-highest) {
    box-shadow: none;
    grid-area: full;
  }
}

.jumbotron_banner_content {
  display: grid;
  padding: rfs(30px 16px 16px);
  position: relative;

  @include media($medium-highest) {
    padding: rfs(68px 52px 33px);
  }

  p {
    @include body-b1;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.jumbotron_banner_image {
  height: auto;
  min-height: 150px;
  object-fit: cover;
  width: 100%;

  @include media($small) {
    grid-area: full;
  }

  div {
    height: 100%;
    width: 100%;
  }
}

.jumbotron_simple {
  grid-template-areas: 'full';
  min-height: 350px;

  @include media($medium) {
    min-height: 450px;
  }

  .jumbotron_banner_image {
    grid-area: full;
    object-fit: cover;

    @include media($medium, down) {
      &::before {
        background: linear-gradient(to top, rgb(0 0 0 / 50%) 0%, rgb(0 0 0 / 0%) 100%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .jumbotron_banner_cta {
    @include container;

    align-self: center;
    background: none;
    color: var(--color-white);
    grid-area: full;
    width: 100%;

    @include media($medium) {
      align-self: unset;
    }
  }

  .jumbotron_banner_content {
    @include media($medium) {
      padding: rfs(68px var(--global-padding) 33px);
      width: 65%;
    }
  }

  .jumbotron_banner_buttons {
    flex-flow: wrap;
    flex-direction: unset;
    justify-content: unset;
  }
}
