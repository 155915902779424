@use 'global' as *;
.content {
  height: fit-content;
  margin: auto 0;

  @include media($medium) {
    max-width: 450px;
  }

  span {
    @include h3;

    display: block;
    font-family: var(--font-family-headings);
    font-weight: 300;
    margin: 0 0 10px;
  }
}

.link {
  display: block;
  margin-top: 20px;
}

.author_text {
  margin-bottom: 10px;

  @include font-size(12px, 20px);
}

.image_wrapper > span {
  box-shadow: 0 2px 5px rgb(0 0 0 / 24%);
  display: block;
}
