@use 'global' as *;
// @forward './global/utilities/';

// --------------------------------------------------------

.container {
  @include container;
}

// --------------------------------------------------------

.error-message {
  color: var(--color-yellow);

  &::before {
    color: var(--color-red-primary);
    content: 'Error: ';
    font-family: var(--font-family-medium);
  }
}
