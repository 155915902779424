@use 'global' as *;
.grouped_images {
  display: flex;
  justify-items: space-evenly;
  margin-bottom: 0 !important;
  width: 100%;

  > div {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-direction: column;
    margin: 0 10px 20px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.center_button {
  display: block;
  margin: auto;
  width: fit-content;
}
