@use 'global' as *;
.card {
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.25s ease-out;
  width: 100%;

  @include hover {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);
  }

  a {
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    height: 100%;

    @include hover {
      color: var(--color-black);
      text-decoration: none;
    }
  }

  &.card_is_media {
    border-radius: 4px 4px 0 0;
    max-width: 562px;

    a {
      align-items: center;
      flex-direction: row;
    }
  }
}

.property_image {
  img {
    display: block;
    height: 205px;
    width: 100%;

    .card_is_small & {
      height: 102px;
    }
  }

  .card_is_media & {
    display: block;
    height: 90px;
    width: 90px;

    img {
      height: 90px;
    }
  }

  > span {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);

    .card_is_media & {
      border-top-right-radius: 0;
      box-shadow: none;
      height: 100% !important;

      span {
        height: 100% !important;
      }
    }
  }
}

// ---------------------------------------------------------

.card_top {
  display: grid;
  position: relative;

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

// ---------------------------------------------------------

.property_status {
  @include reset-list;

  margin: 11px 10px 10px;
  position: absolute;

  li {
    @include font-size(9px, 12px);

    margin-bottom: 5px;
  }

  span {
    background-color: var(--color-black);
    border-radius: 9.3px;
    color: var(--color-white);
    display: inline-block;
    font-family: var(--font-family-medium);
    font-weight: 500;
    padding: 3px 11px;
    position: relative;

    &.property_status_sold {
      background-color: var(--color-red-primary);
    }
  }
}

// ---------------------------------------------------------

.card_bottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

// ---------------------------------------------------------

.property_details_container {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 15px 10px;
  white-space: break-spaces;

  @include hover {
    color: var(--color-black);
    text-decoration: none;
  }

  .card_is_small & {
    flex-direction: column;
    padding: 4px 10px 10px;
  }

  .card_is_media & {
    padding: 4px 10px 0;
  }
}

.property_details {
  @include font-size(12px, 20px);

  margin-left: 12px;
  min-width: 40px;
  text-align: right;
  text-transform: uppercase;

  ul {
    @include reset-list;
  }

  li {
    white-space: nowrap;
  }

  .card_is_small & {
    @include font-size(10px, 20px);

    letter-spacing: -0.2px;
    margin-left: 0;
    text-align: left;

    ul {
      display: flex;

      li:not(:last-of-type) {
        &::after {
          content: '|';
          padding: 0 3px;
        }
      }
    }
  }
}

.property_price {
  @include font-size(24px, 26.5px);

  display: block;
  letter-spacing: -0.3px;
  margin-bottom: 4.5px;

  .card_is_small &,
  .card_is_media & {
    @include font-size(17px, 26px);

    letter-spacing: -0.5px;
    margin-bottom: 2px;
  }
}

.property_address {
  @include font-size(14px, 20px);

  display: block;
  font-family: var(--font-family-medium);

  span {
    display: block;
  }

  .card_is_small &,
  .card_is_media & {
    @include font-size(11px, 15px);

    letter-spacing: -0.2px;
  }
}

.on_market_date {
  @include font-size(14px, 20px);

  background-color: var(--color-purple-alpha15);
  box-decoration-break: clone;
  font-family: var(--font-family-medium);
  padding: 3px;
  text-transform: capitalize;
}

.pending_alert {
  @include font-size(14px, 20px);

  background-color: var(--color-red-tertiary-alpha15);
  box-decoration-break: clone;
  font-family: var(--font-family-medium);
  padding: 3px;
  text-transform: capitalize;
}

// ---------------------------------------------------------

.property_realtor_container {
  align-items: center;
  display: flex;
  margin: 10px 12px 15px 14px;
}

.property_realtors {
  @include font-size(12px, 14px);

  margin-left: 16px;

  span {
    @include font-size(10px, 14px);

    display: block;
  }
}
