@use 'global' as *;
.container {
  min-height: 500px;

  .header {
    font-family: var(--font-family-headings);
    font-size: 32px;
    letter-spacing: -0.5px;
    line-height: 38px;
    margin-bottom: 0;
    padding-bottom: 7px;

    @media (max-width: $medium) {
      font-size: 24px;
      letter-spacing: -1px;
      line-height: 48px;
    }
  }

  .post_container {
    display: flex;
    flex-direction: column;

    .post_row {
      align-items: flex-start;
      border-top: 1px solid var(--color-gray-1);
      display: flex;
      flex-direction: row;
      padding: 12px 0;

      @media (max-width: $medium) {
        padding: 10px 0;
      }

      .post_text {
        font-family: var(--font-family-base);
        font-size: 12px;
        line-height: 20px;
        padding-left: 15px;

        @media (max-width: $medium) {
          line-height: 16px;
        }

        a {
          color: var(--color-black);
        }
      }
    }
  }
}
