@use 'global' as *;
.checkbox_label {
  @include font-size(12px, 19px);

  cursor: pointer;
  display: flex;
  margin-bottom: 10px;

  &.contains_checkbox_with_icon {
    align-items: center;
  }
}

// ---------------------------------------------------------

.checkbox {
  align-items: center;
  border: 1px solid var(--color-gray-5);
  border-radius: 4px;
  color: var(--color-white);
  display: grid;
  height: 19.2px;
  justify-content: center;
  margin-right: 6px;
  position: relative;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  width: 19.2px;

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  input {
    display: none;
  }

  &.is_checked {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
}

// ---------------------------------------------------------

.has_icon {
  color: var(--color-black);
  height: 30px;
  margin-right: 10px;
  width: 30px;

  &.is_checked {
    color: var(--color-white);
  }
}

// ---------------------------------------------------------

.header {
  @include font-size(12px, 20px);

  display: block;
  font-family: var(--font-family-medium);
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;

  @include media($small) {
    margin-bottom: 14px;
  }
}

// ---------------------------------------------------------

.layout_is_half,
.layout_is_third {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -10px;
}

.layout_is_half {
  > * {
    width: calc(50% - 5px);
  }
}

.layout_is_third {
  > * {
    width: calc(33.3% - 5px);
  }
}

.layout_flex_row {
  display: flex;
  justify-content: space-between;

  label {
    margin-bottom: 0;

    span {
      font-family: var(--font-family-medium);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 0;
    }
  }
}
