@use 'global' as *;
/* stylelint-disable selector-id-pattern */
@use 'sass:math';

$dots-size: 6px;

.slide_item {
  height: auto !important;
}

.carousel_container {
  height: auto;
  margin: 0 auto;
  overflow-x: hidden;
  padding-left: 0;
  position: relative;

  @include media($small) {
    margin: 0 auto;
    max-width: rfs(1038px);
    width: 100%;
  }

  &[data-theme='cards'] {
    max-width: 100vw;
    padding: rfs(40px 0 35px var(--global-padding));
    width: 100%;

    @include media($small) {
      padding: rfs(50px var(--global-padding) 35px);
    }

    .inner_container {
      margin: 0 auto;
      max-width: var(--global-max-width);
      position: relative;
    }

    .carousel_heading {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 1038px;
      padding-right: var(--global-padding);
      text-align: center;
    }

    .carousel_cards_container {
      margin: auto;
      width: 85%;

      @include media($small-highest, down) {
        width: 100%;
      }
    }

    .next_el,
    .prev_el {
      align-items: center;
      background: var(--color-gray-1);
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
      display: flex;
      height: 42px;
      justify-content: center;
      position: absolute;
      top: 50%;
      transition: background ease-in-out 0.3s;
      width: 42px;

      @include media($small-highest, down) {
        display: none;
      }

      &:hover {
        background: var(--color-gray-2);
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%), 0 0 2px 0 rgb(0 0 0 / 20%);
      }
    }

    .next_el {
      right: 4px;
    }

    .prev_el {
      left: 4px;
    }
  }

  &[data-theme='listings'] {
    max-width: 100vw;
    width: 100%;

    .inner_container {
      margin: 0 auto;
      max-width: var(--global-max-width);
      position: relative;
    }

    .carousel_cards_container {
      width: 100%;
    }
  }

  &[data-theme='text-only'] {
    height: 100%;
    margin: 0;
    max-width: 100vw;
    padding: rfs(40px var(--global-padding) 35px);
    width: 100%;

    @include media($small) {
      padding: rfs(50px 0 35px);
    }

    .carousel_heading {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 1038px;
      padding-right: var(--global-padding);
      text-align: center;
    }

    .carousel_cards_container {
      margin: auto;
      max-width: rfs(1038px);
      width: 85%;

      @include media($small-highest, down) {
        width: 100%;
      }
    }

    .text_content {
      background: var(--color-white);
      border-left: solid 1px var(--color-gray-3);
      border-right: solid 1px var(--color-gray-3);
      border-top: solid 1px var(--color-gray-3);
      box-shadow: 0 0 2px 0 rgba(0 0 0 / 26%);
      height: 100%;
      padding: rfs(51px 36px);
      text-align: center;
    }
  }

  &[data-theme='section'] {
    max-width: unset;

    .carousel_cards_container {
      display: flex;
      flex-direction: column-reverse;
      margin: auto;
      max-width: var(--global-max-width);
      overflow-x: hidden;
      padding: rfs(31px 0 28px);

      @include media($small) {
        align-items: center;
        flex-direction: row;
      }

      > div {
        width: 100%;

        @include media($small) {
          width: 50%;
        }
      }
    }

    .dots {
      margin-left: auto;
      margin-right: auto;

      @include media($medium) {
        padding-left: 51px;
      }
    }

    .card_link {
      margin-top: 0.5rem;
    }

    .card_padded_section {
      height: 100%;
      padding-left: 5px;
      padding-right: 5px;
    }

    .card_wrapper_section {
      height: 100%;
      min-height: 355px;
      position: relative;
    }

    .card_overlay_section {
      background-image: url('../../../public/storybook/card-property-image@3x.jpg');
      background-position: center;
      background-size: cover;
      border-bottom: 11px solid var(--color-red-primary);
      bottom: 0;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 45%);
      left: 0;
      position: absolute;
      right: 0;
      top: 50px;
      width: 100%;
      z-index: -1;

      @include media($medium) {
        height: 100%;
        left: 53px;
        top: unset;
        width: auto;
      }

      &::after {
        background: linear-gradient(to top, rgb(255 255 255 / 90%), var(--color-white) 100%);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }

    .card_details_section {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media($medium) {
        flex-direction: row;
      }

      .person_image_section {
        border: 0;
        width: 100px;

        > div > span {
          box-shadow: 0 2px 6px 0 rgb(0 0 0 / 25%);
        }

        img {
          border: none;
          border-width: 0;
          display: block;
        }

        @include media($medium) {
          margin-bottom: 52px;
          margin-top: 60px;
          max-width: 159px;
          width: 150%;
        }
      }

      .text_content {
        padding: rfs(51px 36px);
      }
    }

    .carousel_heading_section {
      padding: rfs(51px 36px);
      text-align: center;

      @include media($small) {
        text-align: left;
      }

      @include media($medium) {
        margin-bottom: 38px;
      }
    }
  }

  &[data-theme='full-width'],
  &[data-theme='ldp'] {
    margin: 0;
    max-width: 100vw;
    padding: rfs(40px 0 35px var(--global-padding));
    width: 100%;

    &.one_slide {
      padding: 0;
    }

    @include media($small) {
      padding: rfs(50px 0 35px);
    }

    .carousel_heading {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 1038px;
      padding-right: var(--global-padding);
      text-align: center;

      @include media($small) {
        padding-right: 0;
      }
    }

    .dots {
      margin: 30px auto 0;
      max-width: rfs(1038px);
    }

    .card_wrapper {
      border: solid 1px var(--color-gray-3);
      border-bottom: 12px solid var(--color-red-primary);
      box-shadow: 0 0 2px 0 rgba(0 0 0 / 26%);
      height: 100%;
      margin: 0;
      max-width: rfs(1038px);
      min-width: unset;
      overflow-x: hidden;

      @include media($small) {
        border: none;
        box-shadow: none;
        grid-template-columns: repeat(2, minmax(10px, 1fr));
        margin: 0 auto;
      }
    }

    .text_content {
      background: var(--color-white);
      margin: 0;
      max-width: unset;
      padding: rfs(22px 13px 28px 14px);

      @include media($small) {
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: rfs(22px 40px 28px);
      }
    }
  }

  &[data-theme='ldp'] {
    padding-top: 0 !important;

    .inner_container {
      margin-bottom: 30px;
    }

    .slide_item {
      padding: 2px;

      .card_wrapper {
        box-shadow: 0 0 2px 0 rgba(0 0 0 / 26%);
      }
    }
  }

  &[data-background='gray'] {
    background: var(--color-gray-1);
  }

  &[data-background='black'] {
    background: var(--color-black);
    color: var(--color-white);

    .next_el,
    .prev_el {
      color: var(--color-black);

      &:hover {
        background: var(--color-gray-3);
      }
    }
  }
}

.text_content {
  color: black;

  h3 {
    margin-bottom: 3px;

    @include body-b4;
  }

  p {
    margin-bottom: 13px;

    @include body-b1;
  }

  a {
    @include link;
    @include font-size(12px, 20px);

    text-decoration: underline;
  }

  .quote_text {
    font-style: italic;

    @include body-b1;
  }

  .author_text {
    display: flex;
    flex-direction: column;

    @include body-b1;

    p {
      margin-bottom: 0;

      @include font-size(12px, 20px);
    }
  }
}

.carousel_heading {
  @include media($small) {
    max-width: rfs(1038px);
  }

  display: flex;
  flex-direction: column;

  .contentful_wrapper,
  .carousel_header_content {
    width: 75%;
  }

  .carousel_sub_header {
    display: flex;
    justify-content: center;
  }
}

.carousel_heading,
.carousel_heading_section {
  h2 {
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 300;

    @include body-b1;
  }

  p {
    @include body-b1;
  }
}

.card_image {
  height: 150px;
  position: relative;

  @include media($small) {
    height: 100%;
  }

  img {
    height: 100%;
    max-height: 355px;
    object-fit: cover;
    width: 100%;
  }

  div {
    height: 100% !important;
    width: 100% !important;
  }
}

.card_wrapper {
  background: white;
  border-bottom: 12px solid var(--color-red-primary);
  flex-direction: column;
  height: 100%;
  max-width: calc(100vw - (var(--global-padding) * 2));
  min-height: 355px;
  position: relative;

  @include media($small) {
    display: grid;
    margin: 0 0 0 50px;
  }
}

.dots {
  display: flex;
  justify-content: center;
  margin-left: 25px;
  margin-top: 20px;
  max-width: calc(100vw - (var(--global-padding) * 2));
  padding: $dots-size 0;

  @include media($small) {
    margin-left: 0;
  }

  .dot {
    background: var(--color-gray-3);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: $dots-size;
    margin: 0 math.div($dots-size, 2);
    padding: math.div($dots-size, 2);
    width: $dots-size;

    &:not(.active):hover {
      background: var(--color-red-primary);
    }

    &:focus {
      outline: none;
    }

    &.active {
      background: var(--color-gray-4);
    }
  }
}

.link_container {
  .card_wrapper {
    span {
      align-items: center;
      color: var(--color-red-primary);
      display: flex;
      font-weight: var(--font-weight-regular) !important;
      text-decoration: underline;

      @include link;
      @include font-size(12px, 20px);
    }
  }
}

.link_container:hover {
  cursor: pointer;
  text-decoration: none !important;
}
