@use 'global' as *;
// ---------------------------------------------------------

.button {
  @include default-button;

  &:disabled {
    @include hover {
      cursor: default;
    }
  }
}

// --------------------------------------------------------- | themes

.theme_black {
  background-color: var(--color-black);
  border-color: var(--color-black);
  justify-content: center;
  text-decoration: none !important;

  @include hover {
    background-color: var(--color-gray-5);
    border-color: var(--color-gray-5);
  }

  &:disabled {
    background-color: var(--color-white);
    border-color: var(--color-gray-5);
    color: var(--color-gray-5);

    @include hover {
      background-color: var(--color-white);
      border-color: var(--color-gray-5);
      color: var(--color-gray-5);
    }
  }
}

// ---------------------------------------------------------

.theme_gray_outline {
  background-color: var(--color-white);
  border-color: var(--color-gray-4);
  color: var(--color-gray-5);

  @include hover {
    background-color: var(--color-gray-1);
    border-color: var(--color-gray-5);
    color: var(--color-gray-5);
  }
}

// ---------------------------------------------------------

.theme_red {
  background-color: var(--color-red-primary);
  border-color: var(--color-red-primary);
  justify-content: center;
  text-decoration: none !important;

  @include hover {
    background-color: var(--color-red-secondary);
    border-color: var(--color-red-secondary);
  }

  &:disabled {
    background-color: var(--color-red-primary);
    border-color: var(--color-red-primary);
    opacity: 50%;

    @include hover {
      background-color: var(--color-red-primary);
      border-color: var(--color-red-primary);
    }
  }
}

.theme_red_outline {
  background-color: var(--color-white);
  border-color: var(--color-red-primary);
  color: var(--color-red-primary);

  @include hover {
    border-color: var(--color-red-secondary);
    color: var(--color-red-secondary);
  }

  &:disabled {
    background-color: var(--color-white);
    border-color: var(--color-red-primary);
    opacity: 50%;
  }
}

// ---------------------------------------------------------

.theme_with_icon {
  @include font-size(12px, 18px);

  .icon_container {
    margin-right: unset;
  }

  background-color: var(--color-white);
  border-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  color: var(--color-black);
  display: flex;
  font-family: var(--font-family-base);
  padding: 8px 14px;

  @include media($x-small-highest) {
    font-family: var(--font-family-medium);
    padding: 9px 20px;
  }

  svg {
    display: flex;
    margin-right: 7px;

    @include media($x-small-highest) {
      margin-right: 10px;
    }
  }

  @include hover {
    border-color: var(--color-white);
    color: var(--color-red-primary);
  }
}

// ---------------------------------------------------------

.theme_text_only_button {
  @include font-size(12px, 20px);

  .icon_container {
    margin-right: 5px;
  }

  color: var(--color-gray-4);
  font-family: var(--font-family-medium);
  font-weight: 500;
  text-decoration: underline;
}

.theme_red_text_only_with_caret_button {
  @include font-size(12px, 20px);

  align-items: center;
  border: none;
  color: var(--color-red-primary);
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-medium);
  justify-content: flex-start !important;
  text-decoration: underline;

  @include hover {
    color: var(--color-red-primary);
    text-decoration: underline;
  }

  @include media($smallest) {
    padding-left: 0 !important;
  }
}

.theme_text_only_with_caret_button {
  @include font-size(12px, 20px);

  align-items: center;
  border: none;
  color: var(--color-gray-5);
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-medium);
  justify-content: flex-start;
  text-decoration: underline;

  @include hover {
    color: var(--color-red-primary);
    text-decoration: underline;
  }

  @include media($smallest) {
    padding-left: 0 !important;
  }
}

.theme_text_only_red_button {
  @include a;

  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
}

.theme_text_only_black_button {
  @include a;

  border: 0;
  color: var(--color-black);
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;

  @include hover {
    color: var(--color-gray-3);
  }
}

.theme_text_only_white_button,
.theme_text_only_white_button_with_caret_button {
  @include a;

  border: 0;
  color: var(--color-white);
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;

  @include hover {
    color: var(--color-gray-3);
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon_container {
  margin-right: 12px;
}
