@use 'global' as *;
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab_slider {
  margin: 19px 0 40px;
  max-width: 490px;
  width: 100%;
}

.content_container {
  margin-bottom: 19px;
  max-width: 766px;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 32px;
    letter-spacing: -0.5px;
    line-height: 38px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}
