@use 'global' as *;
// ---------------------------------------------------------

.grid {
  display: grid;
  width: 100%;
}

// ---------------------------------------------------------

.layout_is_third {
  justify-items: center;

  @include media($small-lowest) {
    grid-template-columns: 1fr 1fr;
  }

  @include media($small-highest) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.layout_is_half {
  @include media($small) {
    grid-template-columns: 1fr 1fr;
  }
}

.layout_is_one_third_two_thirds {
  @include media($small) {
    grid-template-columns: 1fr 2fr;
  }
}

.layout_is_two_thirds_one_third {
  @include media($small, down) {
    gap: calc(var(--grid-gutter) / 2);
  }

  @include media($small) {
    grid-template-columns: 2fr 1fr;
  }
}

// ---------------------------------------------------------

.vertical_center {
  align-items: center;
}

.vertical_top {
  align-items: start;
}

.vertical_bottom {
  align-items: end;
}

// ---------------------------------------------------------

$gutters: '0', '12', '15', '20', '25', '30', '50';

@each $gutter in $gutters {
  .gutter_#{$gutter} {
    @if $gutter == '0' {
      --grid-gutter: #{$gutter}%;
    } @else {
      --grid-gutter: #{$gutter}px;
    }

    gap: var(--grid-gutter);
  }
}
