@use 'global' as *;
.select_container {
  margin-top: -3px;

  label {
    @include font-size(9px, 19px);
  }
}

// ---------------------------------------------------------

.select {
  align-items: center;
  display: flex;

  select {
    background-color: var(--color-white);
    background-image: url('../../../public/images/icons/icons-arrow-default-down.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;
    border: solid 1px var(--color-gray-3);
    border-radius: 4px;
    color: var(--color-black);
    cursor: pointer;
    min-width: 140px;
    padding: 8px 10px 9px;

    @include media($large-lowest) {
      @include font-size(12px, 18px);
    }

    &:focus {
      border: 1px solid var(--color-black);
    }
  }
}

.theme_request_modal {
  .select {
    select {
      background-color: var(--color-gray-2);
      border-radius: 0;
      height: 4vh;
      transition: border-color 0.25s ease-in;
    }

    select:focus {
      border: 0.0625rem solid var(--color-gray-4);
    }
  }
}

// ---------------------------------------------------------

.header {
  @include font-size(12px, 20px);

  display: block;
  font-family: var(--font-family-medium);
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;

  @include media($small) {
    margin-bottom: 14px;
  }
}

.minmax_select_container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, calc(50% - 5px));
}

.input_label {
  @include font-size(12px, 20px);

  font-family: var(--font-family-medium);
  font-weight: 500;

  span {
    display: block;
    margin-bottom: 8px;
  }
}

.input_container {
  position: relative;
  width: 100%;
}

.remove_label {
  background-image: url('../../../public/images/icons/icons-actions-close.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  height: 8px;
  margin: 0 5px;
  margin-bottom: 0 !important;
  width: 8px;

  &:hover {
    cursor: pointer;
  }
}

.multiple_select_heading {
  @include font-size(12px, 20px);

  font-family: var(--font-family-medium);
}
