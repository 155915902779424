@use 'global' as *;
/* stylelint-disable selector-id-pattern */
@use 'sass:math';

$dots-size: 6px;

.carousel_container {
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;

  @include media($small) {
    margin: 0 auto;
    max-width: rfs(1038px);
    width: 100%;
  }

  &[data-theme='section'] {
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
    padding: rfs(31px 0 28px);

    @include media($small) {
      align-items: center;
      flex-direction: row;
      max-width: unset;
    }

    > div {
      width: 100%;

      @include media($small) {
        width: 50%;
      }
    }

    .dots {
      margin-left: auto;
      margin-right: auto;

      @include media($medium) {
        padding-left: 51px;
      }
    }

    .card_padded_section {
      padding-left: 5px;
      padding-right: 5px;
    }

    .card_wrapper_section {
      height: 100%;
      min-height: 355px;
      position: relative;
    }

    .card_overlay_section {
      background-image: url('../../../public/storybook/card-property-image@3x.jpg');
      background-position: center;
      background-size: cover;
      border-bottom: 11px solid var(--color-red-primary);
      bottom: 0;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 45%);
      left: 0;
      position: absolute;
      right: 0;
      top: 50px;
      width: 100%;
      z-index: -1;

      @include media($medium) {
        height: 100%;
        left: 53px;
        top: unset;
        width: auto;
      }

      &::after {
        background: linear-gradient(to top, rgb(255 255 255 / 90%), var(--color-white) 100%);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }

    .card_details_section {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media($medium) {
        flex-direction: row;
      }

      .person_image_section {
        border: 0;
        width: 100px;

        > div > span {
          box-shadow: 0 2px 6px 0 rgb(0 0 0 / 25%);
        }

        img {
          border: none;
          border-width: 0;
          display: block;
        }

        @include media($medium) {
          margin-bottom: 52px;
          margin-top: 60px;
          max-width: 159px;
          width: 150%;
        }
      }

      .text_content {
        padding: rfs(51px 36px);
      }
    }

    .carousel_heading_section {
      padding: rfs(51px 36px);
      text-align: center;

      @include media($small) {
        text-align: left;
      }

      @include media($medium) {
        margin-bottom: 38px;
      }
    }
  }

  &[data-background='gray'] {
    background: var(--color-gray-1);
  }

  &[data-theme='full-width'] {
    margin: 0;
    max-width: 100vw;
    padding: rfs(40px 0 35px var(--global-padding));
    width: 100%;

    @include media($small) {
      padding: rfs(50px 0 35px);
    }

    .carousel_heading {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 1038px;
      padding-right: var(--global-padding);
      text-align: center;

      @include media($small) {
        padding-right: 0;
      }
    }

    .dots {
      margin: 30px auto 0;
      max-width: rfs(1038px);
    }

    .card_wrapper {
      border: solid 1px var(--color-gray-3);
      box-shadow: 0 0 2px 0 rgba(0 0 0 / 26%);
      margin: 0;
      max-width: rfs(1038px);
      min-width: unset;

      @include media($small) {
        box-shadow: none;
        grid-template-columns: repeat(2, minmax(10px, 1fr));
        margin: 0 auto;
      }
    }

    .text_content {
      background: var(--color-white);
      border-bottom: 12px solid var(--color-red-primary);
      margin: 0;
      max-width: unset;
      padding: rfs(22px 13px 28px 14px);

      @include media($small) {
        border: none;
        padding: rfs(51px 63px 0 36px);
      }
    }
  }
}

.text_content {
  h2 {
    margin-bottom: 3px;

    @include body-b4;
  }

  p {
    margin-bottom: 13px;

    @include body-b1;
  }

  a {
    @include link;
    @include font-size(12px, 20px);

    text-decoration: underline;
  }

  .quote_text {
    font-style: italic;

    @include body-b1;
  }

  .author_text {
    display: flex;
    flex-direction: column;

    @include body-b1;

    p {
      margin-bottom: 0;

      @include font-size(12px, 20px);
    }
  }
}

.carousel_heading {
  @include media($small) {
    max-width: rfs(1038px);
  }

  display: flex;
  flex-direction: column;

  .contentful_wrapper,
  .carousel_header_content {
    width: 75%;
  }

  .carousel_sub_header {
    display: flex;
    justify-content: center;
  }
}

.carousel_heading,
.carousel_heading_section {
  h2 {
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 300;

    @include body-b1;
  }

  p {
    @include body-b1;
  }

  a {
    @include link;
    @include font-size(12px, 20px);

    text-decoration: underline;
  }
}

.card_image {
  height: 100%;
  min-height: 150px;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;

    @media only screen and (max-width: 719px) {
      height: 150px;
    }
  }

  div {
    height: 100% !important;
    width: 100% !important;
  }
}

.card_wrapper {
  display: grid;
  height: calc(100% + 100px);
  margin: 100px 0 0;
  max-width: calc(100vw - (var(--global-padding) * 2));
  min-height: 355px;
  position: relative;

  @include media($small) {
    margin: 0 0 0 50px;
  }
}

.dots {
  display: flex;
  justify-content: center;
  margin-left: 25px;
  margin-top: 20px;
  max-width: calc(100vw - (var(--global-padding) * 2));
  padding: $dots-size 0;

  @include media($small) {
    margin-left: 0;
  }

  .dot {
    background: var(--color-gray-3);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: $dots-size;
    margin: 0 math.div($dots-size, 2);
    padding: math.div($dots-size, 2);
    width: $dots-size;

    &:not(.active):hover {
      background: var(--color-red-primary);
    }

    &:focus {
      outline: none;
    }

    &.active {
      background: var(--color-gray-4);
    }
  }
}
