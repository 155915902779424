@use 'global' as *;
.placeholder_container {
  bottom: -7px;
  display: block;
  opacity: 100%;
  padding: 0.5em 0.5em 1em 1em;
  position: fixed;
  right: 0;
  transition: opacity 250ms linear;
  z-index: 2147483640;
}

.placeholder_fade_out {
  opacity: 0%;
}

.placeholder_hidden {
  display: none;
}

.placeholder_button {
  background-color: var(--color-gray-2);
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 12.5%) 0 0.3621px 0.9416px -1px, rgb(0 0 0 / 18%) 0 3px 7.8px -2px;
  height: 60px;
  width: 60px;

  svg {
    height: 32px;
    width: 32px;
  }
}
