@use 'global' as *;
.navigation {
  @include reset-list;

  display: flex;

  a {
    @include header-links;
  }

  &.background_is_white {
    a {
      color: var(--color-black) !important;
    }
  }
}
