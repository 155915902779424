@use 'global' as *;
.sell_with_sibcy {
  align-items: center;
  display: grid;
  position: relative;
  width: 100%;

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

// ---------------------------------------------------------

.sell_with_sibcy_container {
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 13%);
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: rfs(50px 0);
  max-height: 350px;
  max-width: rfs(490px);
  min-height: rfs(325px);
  min-width: 90%;
  padding: rfs(75px 20px 60px);
  text-align: center;
  z-index: 1;

  @include media($small) {
    justify-self: unset;
    min-width: 80%;
  }

  @media h2 {
    @include font-size(32px, 38px);

    letter-spacing: -0.5px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: rfs(25px);
  }

  .buyside_widget_container {
    margin-bottom: rfs(60px);
    max-width: 325px;
    width: 100%;
  }
}

// ---------------------------------------------------------

.sell_with_sibcy_background_image {
  height: 100%;
  position: relative;

  @include media($small) {
    margin-left: rfs(70px);

    &::before {
      background-color: var(--color-red-primary);
      content: '';
      height: 100%;
      position: absolute;
      width: 18px;
      z-index: 1;
    }
  }

  > div {
    min-height: 100%;
  }
}
