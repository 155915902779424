@use 'global' as *;
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search_bar {
  margin-bottom: 20px;
  width: 100%;
}

.state_sort {
  margin-bottom: 16px;
  width: 100%;

  @include media($small-lowest) {
    margin-bottom: 0;
    max-width: 250px;
  }
}

.zero_state {
  @include zero-state;

  width: 100%;
}
