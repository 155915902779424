@use 'global' as *;
.container_footer_at_bottom {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
}

.theme_gradient {
  background-image: linear-gradient(to bottom, var(--color-white), var(--color-gray-1));
}

.preview_alert {
  align-items: center;
  background: var(--color-gray-2);
  bottom: 0;
  box-shadow: 0 -2px 4px var(--color-gray-4-alpha40);
  display: flex;
  height: 50px;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 20;

  h3 {
    margin: 0;
  }
}

.preview_desktop_title {
  display: none;

  @include media($x-small) {
    display: initial;
  }
}

.preview_mobile_title {
  @include media($x-small) {
    display: none;
  }
}
