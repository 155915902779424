@use 'global' as *;
.radio_buttons {
  display: flex;
  justify-content: space-between;

  &.are_vertical {
    flex-direction: column;

    .radio_button {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &.are_centered {
    justify-content: center;
  }
}

.radio_button {
  @include font-size(12px, 18px);

  cursor: pointer;

  input {
    appearance: none;
    border: 1px solid var(--color-gray-5);
    cursor: pointer;
  }
}

// --------------------------------------------------------- | themes

.with_label,
.with_label_inside {
  &:not(:last-of-type) {
    margin-right: rfs(20px);
  }

  input {
    border-radius: 50%;
  }
}

// ---------------------------------------------------------

.with_label {
  display: flex;

  input {
    $size: 19.2px;

    height: $size;
    margin-right: 7px;
    transition: border ease-in-out 0.25s;
    width: $size;

    &:checked {
      background-color: var(--color-white);
      border: 6px solid var(--color-black);
    }
  }
}

// ---------------------------------------------------------

.with_label_inside {
  align-items: center;
  display: grid;
  justify-items: center;

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  input {
    $size: 30px;

    background-color: var(--color-white);
    height: $size;
    transition: background-color ease-in-out 0.25s;
    width: $size;

    &:checked {
      background-color: var(--color-black);
      color: var(--color-white);

      + div {
        color: var(--color-white);
      }
    }

    div {
      color: var(--color-black);
      transition: color ease-in-out 0.25s;
    }
  }

  span {
    margin-bottom: 0;
  }
}

// ---------------------------------------------------------

.with_icon {
  display: grid;

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  input {
    border-color: var(--color-gray-3);
    border-radius: 1px;
    height: auto;
    transition: border-color ease-in-out 0.25s;
    width: 100px;

    @include media($x-small, down) {
      width: 86px;
    }

    &:checked {
      border-color: var(--color-red-primary);

      + div {
        color: var(--color-red-primary);

        span {
          color: var(--color-red-primary);
        }
      }
    }
  }

  > div {
    align-items: center;
    color: var(--color-gray-4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: color ease-in-out 0.25s;
  }

  .label_text {
    color: var(--color-black);
    margin-bottom: 18px;
    transition: color ease-in-out 0.25s;
  }

  .icon_container {
    align-items: flex-end;
    display: flex;
    margin: 18px 0;
    min-height: 35px;
  }
}

// ---------------------------------------------------------

.with_label_inside_square {
  display: grid;

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  input {
    border-color: var(--color-black);
    border-radius: 0;
    height: 150px;
    transition: all ease-in-out 0.25s;
    width: 150px;

    @include media($small, down) {
      height: 100px;
      width: 100px;
    }

    &:checked {
      border-color: var(--color-red-primary);

      + div {
        background-color: var(--color-red-primary);
        transition: all ease-in-out 0.25s;

        span {
          color: var(--color-white);
        }
      }
    }
  }

  > div {
    align-items: center;
    color: var(--color-gray-4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: color ease-in-out 0.25s;

    span {
      color: var(--color-black);
      display: flex;
      font-size: x-large;
      font-weight: 700;
      text-align: center;
      width: 150px;

      @include media($small, down) {
        font-size: medium;
        width: 100px;
      }
    }
  }
}

// ---------------------------------------------------------

.checkboxes_container {
  margin-bottom: -8px;
  margin-top: 5px;
}

// ---------------------------------------------------------

.header {
  @include font-size(12px, 20px);

  display: block;
  font-family: var(--font-family-medium);
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;

  @include media($small) {
    margin-bottom: 14px;
  }
}
