@use 'global' as *;
.link_list {
  @include reset-list;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

// ---------------------------------------------------------

.contains_arrow_icons {
  a {
    align-items: center;
    display: flex;

    svg {
      margin-left: 3.5px;
    }
  }
}
