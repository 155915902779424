@use 'font' as *;
@use 'media' as *;
@use 'support' as *;

// ---------------------------------------------------------

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @content;
  }
}

@mixin h1 {
  @include font-size(48px, 50px);

  letter-spacing: -1px;
}

@mixin h2 {
  letter-spacing: -0.25px;

  @include font-size(32px, 38px);

  @include media($small) {
    letter-spacing: -0.5px;
  }
}

@mixin h3 {
  @include font-size(24px, 30px);

  letter-spacing: -0.25px;
}

@mixin h4 {
  font-size: font-size(18px, 24px);
  letter-spacing: -0.25px;
}

@mixin h5 {
  @include font-size(18px, 18px);
}

// ---------------------------------------------------------

@mixin p {
  @include font-size(16px, 28px);
}

@mixin body-b1 {
  @include font-size(16px, 24px);

  font-family: var(--font-family-base);
}

@mixin body-b2 {
  @include font-size(18px, 22px);

  font-family: var(--font-family-headings);

  @include media($small) {
    @include font-size(20px, 24px);
  }
}

@mixin body-b3 {
  @include font-size(12px, 18px);

  font-family: var(--font-family-medium);
  font-weight: 500;
  letter-spacing: 0.5px;

  @include media($small) {
    @include font-size(14px, 18px);
  }
}

@mixin body-b4 {
  @include font-size(20px, 24px);

  font-family: var(--font-family-medium);
  font-weight: 500;

  @include media($small) {
    @include font-size(25px, 30px);
  }
}

// ---------------------------------------------------------

@mixin a {
  color: var(--color-red-primary);
  text-decoration: none;
  transition: color 0.25s ease-out;

  @include hover {
    color: var(--color-red-secondary);
    text-decoration: underline;
  }
}

@mixin link {
  align-items: center;
  display: inline-flex;
  font-family: var(--font-family-medium);
  letter-spacing: -0.18px;
  text-decoration: underline;

  @include font-size(11px, 18px);

  @include media($small) {
    gap: 4px;

    @include font-size(12px, 18px);
  }
}

// -----------------------------------------------

@mixin field-label {
  @include font-size(12px, 20px);

  font-family: var(--font-family-medium);
  font-weight: 500;
}
