// --------------------------------------------------------

@mixin reset-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

// --------------------------------------------------------

@mixin reset-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    padding: 0;
  }
}

// ---------------------------------------------------------

@mixin reset-select {
  appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  width: 100%;
}
