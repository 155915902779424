@use 'global' as *;
.social_nav {
  $size: 19px;

  display: flex;
  margin-right: -11px;

  @include media($small) {
    margin-left: -6.5px;
    margin-right: 0;
  }

  a {
    align-items: center;
    color: var(--color-white);
    display: flex;
    justify-content: center;
    margin-right: 4.5px;
    min-height: 32px;
    min-width: 32px;
    width: $size;

    svg {
      height: $size;
      width: $size;
    }
  }
}
