@use 'global' as *;
/* stylelint-disable selector-class-pattern */
$default: var(--color-gray-1);
$success: var(--color-gray-1);
$danger: var(--color-gray-1);
$info: var(--color-gray-1);
$warning: var(--color-gray-1);
$awesome: var(--color-gray-1);

@import 'animate.css/animate.min.css';
@import 'react-notifications-component/dist/scss/notification';

.rnc__notification-title,
.rnc__notification-message {
  color: var(--color-black);
  font-size: 80%;
}

.rnc__notification-item {
  height: 75px;
}

.rnc__notification-content {
  padding: 15px;
}
