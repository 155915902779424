@use 'global' as *;
// Styles for Form Component

.form_wrapper {
  display: grid;
  height: auto;
  margin: auto;
  position: relative;
  z-index: 1;

  @include media($small) {
    grid-template-areas: 'full';
  }
}

.form_bg_img {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  > * {
    height: 100%;
    position: relative !important;
    width: 100%;
  }
}

.form_container {
  margin: auto;
  padding: 15px 10px;
  width: 80%;

  @include media($small) {
    display: flex;
    grid-area: full;
    justify-content: center;
    width: 100%;
  }
}

.form_inside_container {
  background-color: var(--color-white);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 17%);
  padding: rfs(50px 36px 40px);
  position: relative;
  width: 100%;
  z-index: 1;

  @include media($small) {
    max-width: 457px;
  }
}

.form {
  color: black;
  display: grid;
  gap: 20px;

  p {
    text-align: left;
  }
}

.form_title {
  @include h2;

  padding: 0;
}

.form_row {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;

  @include media($small) {
    flex-direction: row;
    gap: 14px;
  }

  &[data-flex='row'] {
    flex-direction: row;
  }
}

.form_support_block {
  align-items: center;
  background: var(--color-gray-1);
  display: flex;
  min-height: 250px;
  padding: rfs(36px 26px 40px);
  position: relative;

  @include media($small) {
    margin: 60px 0;
    padding: rfs(60px 36px);
    width: clamp(300px, 45vw, 460px);
  }
}

.form_support_block_text {
  position: relative;
  z-index: 1;

  h2 {
    @include h3;
  }

  p {
    @include body-b1;
  }
}

.form_image {
  margin-bottom: 10px;
}

// ---------------------------------------------------------

.theme_office {
  .form_title {
    font-family: var(--font-family-headings);
    margin: -10px auto;
    text-align: center;
  }

  .form {
    p {
      @include font-size(12px, 20px);

      text-align: center;
    }
  }
}

// ---------------------------------------------------------

.theme_no_background {
  &.form_wrapper {
    width: 100%;
  }

  .form_container {
    width: 100%;
  }

  .form_inside_container {
    background-color: transparent;
    box-shadow: none;
    font-family: var(--font-family-medium);
    padding: 0 10px;
    width: 100%;
  }

  button {
    text-align: center;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
  }
}

// ---------------------------------------------------------

.theme_no_background_no_padding {
  &.form_wrapper {
    width: 100%;
  }

  .form_container {
    padding: 0;
    width: 100%;
  }

  .form_inside_container {
    background-color: transparent;
    box-shadow: none;
    font-family: var(--font-family-medium);
    padding: 0;
    width: 100%;
  }

  button {
    text-align: center;
    width: 100%;
  }

  .disclaimer {
    text-align: center;
  }
}

// ---------------------------------------------------------

.form_success {
  text-align: center;

  div:nth-of-type(1) {
    font-weight: 800;
  }
}

// ---------------------------------------------------------

.error {
  color: var(--color-red-primary);
}

// ---------------------------------------------------------

.disclaimer {
  color: var(--color-gray-4);
  font-family: var(--font-family-base);

  @include font-size(12px, 20px);
}

.previous_step {
  color: var(--color-gray-4);
  font-family: var(--font-family-base);
  text-decoration: underline;
}
