@use 'global' as *;
// TODO this should be turned into a wysiwyg block and use npm @contentful/rich-text-react-renderer

.wrapper {
  display: flex;
  justify-content: center;
}

.text {
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  padding-bottom: 32px;

  @include media($medium) {
    padding: 0;
  }

  h2 {
    @include media($medium) {
      margin-bottom: 10px;
    }
  }

  h3 {
    @include font-size(16px, 20px);

    color: var(--color-red-primary);
    font-family: var(--font-family-bold);
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p {
    @include body-b1;
  }
}

.text_link {
  @include link;

  margin-top: 13px;
  text-decoration: underline;

  @include media($small) {
    @include font-size(12px, 19px);
  }
}

.alignment {
  &_top {
    justify-content: flex-start;
  }

  &_center {
    justify-content: center;
  }

  &_bottom {
    justify-content: flex-end;
  }
}

.text_align {
  &_right {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: right;
    }

    .text_link {
      align-self: flex-end;
    }
  }

  &_center {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }

    div:has(> img) {
      display: flex;
      justify-content: center;
    }

    .text_link {
      align-self: center;
    }
  }

  &_left {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: left;
    }

    div:has(> img) {
      display: flex;
      justify-content: left;
    }

    .text_link {
      align-self: flex-start;
    }
  }
}

.spacing_bottom {
  &_none {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &_small {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &_medium {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
